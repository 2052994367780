<template>
  <div class="align">
    <loader v-show="isLoading"></loader>
    <div class="contentBlock" v-if="!isLoading && smsSend">
      <div class="title">
        <h3>Изменить пароль</h3>
      </div>
      <div class="body">
        <p class="stxt">На ваш номер выслан SMS-код</p>

        <div class="inputBox">
          <label for="ConfirmationCode">SMS-код</label>
          <input type="tel" v-model.trim="ConfirmationCode" id="ConfirmationCode" v-on:blur="checkConfirmationCode">
          <div v-show="errorConfirmationCode.length > 0" v-html="errorConfirmationCode" class="errMsg"></div>
        </div>

        <ul class="stxt">
          <li>Требования к сложности пароля:</li>
          <li v-bind:style="{color:passLength ? 'green':'red'}">1. Минимальная длинна пароля 8 символов</li>
          <li v-bind:style="{color:passUpperCase ? 'green':'red'}">2. Включать прописные символы</li>
          <li v-bind:style="{color:passSmallCase ? 'green':'red'}">3. Включать cтрочные символы</li>
          <li v-bind:style="{color:passNumber ? 'green':'red'}">4. Включать десятичные цифры</li>
        </ul>

        <div class="inputBox">
          <label for="pass1">Новый пароль</label>
          <input type="password" v-model.trim="pass1" id="pass1" v-on:blur="checkNewPass">
          <div v-show="errorPassword.length > 0" v-html="errorPassword" class="errMsg"></div>
        </div>
        <div class="inputBox">
          <label for="pass2">Подтвердите новый пароль</label>
          <input type="password" v-model.trim="pass2" id="pass2" v-on:blur="checkPassConfirm">
          <div v-show="errorPassword2.length > 0" v-html="errorPassword2" class="errMsg"></div>
        </div>

      </div>
      <div v-show="responseError.length > 0" v-html="responseError" class="errMsg"></div>

      <div class="layoutFooter btnIsset">

        <a class="linkColor" v-on:click.prevent="showSmsDesc = !showSmsDesc">SMS-код долго не приходит?</a>
        <ul class="stxt" v-if="showSmsDesc">
          <li>Если возникают проблемы с доставкой SMS-сообщений:</li>
          <li>- Отправьте себе SMS с другого телефона и посмотрите доставляется ли оно.</li>
          <li>- Если сообщение доставляется, попробуйте запросить SMS с кодом подтверждения повторно.</li>
          <li>- Если сообщение не приходит - значит могут наблюдаться проблемы у мобильного оператора или переполнена
            память телефона.
          </li>
        </ul>


        <p class="stxt" v-if="smsCountdown > 0">Повторная отправка смс через {{smsCountdown | convertSecondsinMinAndSeconds}}</p>
        <a v-else class="linkColor" v-on:click.prevent="sendSmScode">Отправить SMS-код повторно</a>

        <button v-on:click="sendAction">Сохранить новый пароль</button>
      </div>


    </div>
    <div v-else class="contentBlock">
      <div class="title">
        <h3>Изменить пароль</h3>
      </div>
      <div class="body">
        <p class="stxt">На ваш номер телефона будет отправлено СМС-сообщение с кодом подтверждения</p>
      </div>
      <div class="layoutFooter">
        <button v-on:click="sendSmScode">Далее</button>
      </div>
    </div>
  </div>
</template>

<script>
  import {mapState} from 'vuex';

  const maxTimeSmSDelay = 600;

  export default {
    name: "ConfirmationCode",
    data() {
      return {
        ConfirmationCode: '',
        pass1:'',
        pass2: '',
        errorPassword: '',
        errorPassword2: '',
        errorConfirmationCode: '',
        responseError: '',
        isLoading: false,
        smsSend: true,
        smsCountdown: -1,
        showSmsDesc: false,
        interlvalID: '',
      }
    },
    methods:{
      checkConfirmationCode() {
        //проверка кода подтверждения
        if (this.ConfirmationCode.length == 0) {
          this.errorConfirmationCode = '<p>Обязательное поле</p>';
          return false;
        }
        if (!this.$bikeFunction.isNumber(this.ConfirmationCode)) {
          this.errorConfirmationCode = '<p>Недопустимые символы в коде подтверждения</p>';
          return false;
        }
        this.errorConfirmationCode = '';
        return true;
      },
      checkPassConfirm() {
        //проверяем пароли на совпадение
        if (this.pass1 !== this.pass2) {
          this.errorPassword2 = '<p>Пароли не совпадают.</p>';
          return false
        } else {
          this.errorPassword2 = '';
        }
        return true;
      },
      checkNewPass() {
        //проверяем пароль на его крутость
        if (!(this.passLength && this.passNumber && this.passSmallCase && this.passUpperCase)) {
          this.errorPassword = 'Не соответствует требованиям сложности пароля';
          return false;
        } else {
          this.errorPassword = '';
        }
        return true;
      },
      sendAction() {
        let canSend = 0;
        //если забубенить в один if проверка стопорнется на первом косяке, последующие не покажуться пользователю
        if (!this.checkNewPass()) {
          canSend += 1;
        }
        if (!this.checkPassConfirm()) {
          canSend += 1;
        }
        if (!this.checkConfirmationCode()) {
          canSend += 1;
        }
        if (canSend > 0) {
          return false;
        }
        this.isLoading = true;
        this.$external.universalRequest({
          externalType: 'zita',
          action:'ConfirmationCode',
          UserID: this.UserID,
          ConfirmationCode: this.ConfirmationCode,
          password: this.pass1,
        })
        .then((res) => {
          localStorage.removeItem('lastTimeSmSSend');
          this.$store.commit('setSessionKey', res.SessionKey);
          this.$router.push('/');
        })
        .catch((res) => {
          if ( res.errorCode > 0 || res.errorCode !== undefined ) {
            alert('Ошибка' + ' ' + res.errorCode + ': ' + res.errorMsg)
          }
        }).finally(()=>{
          this.isLoading = false;
        });
      },
      sendSmScode() {
        this.isLoading = true;
        this.$myApi.checkVersion();
        this.$external.universalRequest({
          action: 'ChangePassword',
          externalType: 'zita',
        })
        .then((res) => {
          let time = new Date;
          localStorage.setItem('lastTimeSmSSend', time.getTime());
          this.canReSendSms();
          this.smsSend = true;
        })
        .catch((res) => {
          if ( res.errorCode > 0 || res.errorCode !== undefined ) {
            alert('Ошибка' + ' ' + res.errorCode + ': ' + res.errorMsg)
          }
        }).finally(() => {
          this.isLoading = false;
        });
      },
      countdownAction() {
        if (this.smsCountdown > 0) {
          this.interlvalID = setTimeout(() => {
            this.smsCountdown -= 1;
            this.countdownAction();
          }, 1000);
        }
      },
      canReSendSms() {
        let time = new Date;
        if (localStorage.getItem('lastTimeSmSSend') == null) {
          localStorage.setItem('lastTimeSmSSend', time.getTime() - (maxTimeSmSDelay * 1000));
        }
        let lastTimeSmSSend = parseInt(localStorage.getItem('lastTimeSmSSend'), 10);
        let deltaTime = time.getTime() - lastTimeSmSSend;
        if (deltaTime < (maxTimeSmSDelay * 1000)) {
          this.smsCountdown = maxTimeSmSDelay - (Math.round(deltaTime / 1000));
          this.countdownAction();
        }
        return false;
      }
    },
    components: {
      loader: () => import('@/components/other/loader'),
    },
    computed:{
      ...mapState({
        UserID: state => state.auth.UserID
      }),
      passLength() {
        return this.pass1.length > 7;
      },
      passNumber() {
        return /\d/.test(this.pass1);
      },
      passSmallCase() {
        return /[a-zа-я]/.test(this.pass1);
      },
      passUpperCase() {
        return /[A-ZА-Я]/.test(this.pass1);
      }
    },
    mounted() {
      this.$parent.selectedSection = 0
      this.responseError = '';
      this.isLoading = false;
      this.$myApi.checkVersion();
      this.canReSendSms();
    },
    deactivated(){
      clearTimeout(this.interlvalID);
    },
  }
</script>

<style scoped>
a{display:block}
@media screen and (min-width: 760px){
  a{margin-bottom: 1vw;}
}
@media screen and (min-width: 1200px){}
</style>
